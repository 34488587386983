/* eslint-disable max-len */
import { TrainingCapacityBuildingGallery } from '@sections/gallery';
import { getSEO, PageSEO } from '@seo';
import { graphql } from 'gatsby';
import React from 'react';

interface Props {
  data: any;
  location: {
    pathname: string;
  };
}

const TrainingCapacityBuilding = ({ data, location }: Props) => {
  const { pathname } = location;
  const seo = getSEO(pathname);

  //* Caption images here
  const trainingCaptions = {
    'image-00': {
      body: '',
      title: '',
    },
  };

  type TrainingImageKey = keyof typeof trainingCaptions;

  const trainingImages = data.training.edges.map((obj: any) => {
    const key: TrainingImageKey = obj.node.name;

    const title =
      trainingCaptions[key] && trainingCaptions[key].title
        ? trainingCaptions[key].title
        : null;
    const body =
      trainingCaptions[key] && trainingCaptions[key].body
        ? trainingCaptions[key].body
        : null;

    return {
      body,
      id: obj.node.id,
      imageData: obj.node,
      title,
    };
  });

  return (
    <>
      <PageSEO
        description={seo.description}
        featureImage="og-gallery.jpg"
        pathname={pathname}
        title={seo.title}
      />
      <TrainingCapacityBuildingGallery trainingImages={trainingImages} />
    </>
  );
};

export default TrainingCapacityBuilding;

export const pageQuery = graphql`
  query {
    training: allFile(
      filter: { sourceInstanceName: { eq: "gallery-training" } }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
